<template>
  <div id="CONTENT">
    <tagline :sitename="sitename" :title="title"></tagline>
    <navidiv></navidiv>
    <!-- H1 -->
    <div class="h1-binder">
      <h1 class="b5">{{ title }}</h1>
      <!-- 検索フォーム -->
      <form method="get" action="/annotations/search" id="SEARCH-FORM" name="Search">
        <input type="text" value="" name="keywords" class="keyword" /><input type="image" src="/img/btn_search.gif" value="" alt="検索" name="" class="submit" />
      </form>
      <!-- /検索フォーム -->
    </div>
    <!-- /H1 -->
    <!-- パンくず -->
    <div id="TOPIC-PATH"><a href="http://ebiki.jp/">トップページ</a> &gt; <a href="/">絵引データベース</a> &gt; <a :href="'/series/view/' + this.series.id">{{ series.title }}</a></div>
    <!-- /パンくず -->
    <!-- コンテンツ -->
    <div id="CONTENT-BODY">
      <!-- H2 -->
      <div class="h2-binder">
        <h2>{{ series.title }}<span>（{{ series.title_kana }}）</span></h2>
        <p class="date">公開日:{{ series.published | display_date | moment("YYYY/MM/DD") }}　最終更新日:{{ series.modified | display_date | moment("YYYY/MM/DD") }}</p>
      </div>
      <!-- /H2 -->
      <!-- 説明文 -->
      <div class="main-description" v-html="this.series.description"></div>
      <!-- /説明文 -->
      <!-- H3 -->
      <h3>錦絵一覧</h3>
      <!-- /H3 -->
      <!-- 表示制御 -->
      <div class="controller">
        <!-- ページ送り -->
        <div class="paginate"><paginate-links for="works" :show-step-links="true" :async="true"></paginate-links></div>
        <!-- /ページ送り -->
        <!-- 表示件数 -->
        <ul class="limit">
          <li :class="[this.limit == 10 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 10}}">表示数 10</router-link></li>
          <li :class="[this.limit == 20 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 20}}">表示数 20</router-link></li>
          <li :class="[this.limit == 50 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 50}}">表示数 50</router-link></li>
          <li :class="[this.limit == 100 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 100}}">表示数 100</router-link></li>
        </ul>
        <!-- /表示件数 -->
        <!-- 総数 -->
        <p class="total">全{{ total }}件</p>
        <!-- /総数 -->
      </div>
      <!-- /表示制御 -->
      <!-- 一覧 -->
      <paginate v-if="total" name="works" :list="works" :per="limit" :tag="'table'" :class="'base-skin'">
        <tr>
          <th scope="col" class="first image">画像</th>
          <th scope="col"><span @click="sortBy('title_kana')" :class="thClass('title_kana')">作品</span></th>
          <th scope="col"><span @click="sortBy('author_kana')" :class="thClass('author_kana')">作者</span></th>
        </tr>
        <template v-for="work in paginated('works')">
          <tr :key="work.id">
            <td><router-link :to="{ path: '/works/view/' + work.id}"><img :src="work.image" :alt="work.title" :title="work.title" width="70" /></router-link></td>
            <td><router-link :to="{ path: '/works/view/' + work.id}">{{ work.title }}</router-link></td>
            <td>{{ work.author }}</td>
          </tr>
        </template>
      </paginate>
      <template v-else>
        <table class="base-skin">
          <tr>
            <th scope="col" class="first image">画像</th>
            <th scope="col"><span @click="sortBy('title_kana')" :class="thClass('title_kana')">作品</span></th>
            <th scope="col"><span @click="sortBy('author_kana')" :class="thClass('author_kana')">作者</span></th>
          </tr>
        </table>
      </template>
      <!-- /一覧 -->
      <!-- 表示制御 -->
      <div class="controller">
        <!-- ページ送り -->
        <div class="paginate"><paginate-links for="works" :show-step-links="true" :async="true"></paginate-links></div>
        <!-- /ページ送り -->
        <!-- 表示件数 -->
        <ul class="limit">
          <li :class="[this.limit == 10 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 10}}">表示数 10</router-link></li>
          <li :class="[this.limit == 20 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 20}}">表示数 20</router-link></li>
          <li :class="[this.limit == 50 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 50}}">表示数 50</router-link></li>
          <li :class="[this.limit == 100 ? 'selected' : '']"><router-link :to="{ path: '/series/view/' + series_id, query: {limit: 100}}">表示数 100</router-link></li>
        </ul>
        <!-- /表示件数 -->
        <!-- 総数 -->
        <p class="total">全{{ total }}件</p>
        <!-- /総数 -->
      </div>
      <!-- /表示制御 -->
    </div>
    <!-- /コンテンツ -->
    <footerdiv></footerdiv>
  </div>
</template>

<script>
/* eslint-disable no-console */
// @ is an alias to /src
import tagline from '@/components/tagline.vue'
import navidiv from '@/components/navi.vue'
import footerdiv from '@/components/footer.vue'

import $ from 'jquery'
import ebikiMixin from '@/mixins/ebikiMixin.js'

export default {
  name: 'series',
  mixins: [ ebikiMixin ],
  components: {
    tagline,
    navidiv,
    footerdiv
  },
  props: {
    series_id: { type: Number, required: true },
    sitename: { type: String, required: true },
    orgname: { type: String, required: true },
    manifest:  { type: Object, required: true },
    manifest_id:  { type: Number, required: true },
    title:  { type: String, required: true },
    meta_description:  { type: String, required: true },
    meta_keywords:  { type: String, required: true },
  },
  data: function () {
    return {
      limit: 20,
      paginate: ['works'],
    };
  },
  filters: {
    display_date: function(value) {
      return value ? value : '0000/00/00'
    }
  },
  computed: {
    series: function() {
      return {
        id: this.series_id,
        title: this.manifest.label,
        title_kana: this.findMetadata(this.manifest.metadata, "name_kana"),
        description: this.manifest.description,
        published: this.findMetadata(this.manifest.metadata, "published"),
        modified: this.findMetadata(this.manifest.metadata, "modified")
      }
    },
    works: function() {
      let data = []
      if (typeof this.manifest.sequences !== 'undefined') {
        if (typeof this.manifest.sequences[0].canvases !== 'undefined') {
          this.manifest.sequences[0].canvases.forEach((_, i) => {
            let work = {
              id: this.manifest.sequences[0].canvases[i]['@id'].match(/\d+$/)[0],
              title: this.manifest.sequences[0].canvases[i].label,
              title_kana: '',
              author: '',
              author_kana: '',
            }
            if (this.manifest.sequences[0].canvases[i].metadata) {
              work.title_kana = this.findMetadata(this.manifest.sequences[0].canvases[i].metadata, "name_kana")
              work.author = this.findMetadata(this.manifest.sequences[0].canvases[i].metadata, "author")
              work.author_kana = this.findMetadata(this.manifest.sequences[0].canvases[i].metadata, "author_kana")
            }
            if (typeof this.manifest.sequences[0].canvases[i].thumbnail !== 'undefined') {
              if (typeof this.manifest.sequences[0].canvases[i].thumbnail['@id'] !== 'undefined') {
                work.image = this.manifest.sequences[0].canvases[i].thumbnail['@id']
              }
            }
            data.push(work)
          });
        }
      }
      if (data) {
        if (this.sort.key) {
          data.sort((a, b) => {
            a = a[this.sort.key]
            b = b[this.sort.key]

            return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.direction === 'desc' ? -1 : 1)
          });
        }
      }
      return data
    },
    total: function() {
      return this.works.length
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.limit = (this.$route.query.limit ? Number(this.$route.query.limit) : 20)
      },
      immediate: true,
      deep: true
    },
    limit: function() {
      $('.select-collapsed p').text('表示数 '+this.limit)
    }
  },
  head: {
    title: function () {
      return {
        inner: this.sitename + ' ' + this.title + ' - ' + this.orgname,
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.meta_description },
        { name: 'keywords', content: this.meta_keywords },
        { property: 'og:title', content: this.sitename + ' ' + this.title + ' - ' + this.orgname },
        { property: 'og:description', content: this.meta_description },
      ]
    }
  }
}
</script>
